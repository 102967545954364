<template>
  <a-select
    allowClear
    :mode="mode"
    show-search
    :maxTagCount="4"
    style="width: 100%"
    :placeholder="_selfPlaceholder"
    :disabled="_selfDisabled"
    v-model="selectedValue"
    :filter-option="filterOption"
    @change="onChange"
  >
    <a-select-option v-for="(item, index) in option" :value="item.code" :key="index">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
// 通过父元素传参获取list
export default {
  props: {
    placeholder: {},
    disabled: {},
    code: {},
    mode: {
      type: String,
      default: '',
    },
    list: [],
  },
  data() {
    return {
      selectedValue: undefined,
      option: [],
    }
  },
  computed: {
    _selfPlaceholder() {
      return this.placeholder
    },
    _selfDisabled() {
      return this.disabled || false
    },
  },
  watch: {
    list: {
      handler() {
        this.getList()
      },
      immediate: true,
    },
    code(val) {
      this.selectedValue = val
    },
  },
  methods: {
    async getList() {
      let list = this.list || []
      this.option = list
      if (this.code != undefined) {
        this.onChange(this.code)
      } else if (list.length === 1) {
        this.onChange(list[0].code)
      }
    },
    onChange(value) {
      this.selectedValue = value
      this.$emit('update:code', value)
      let item = this.option.find((elem) => elem.code === value)
      this.$emit('change', item)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>
