import store from '@/store'
// js 数字每三位数加一个逗号,
export function toThousands(num) {
  let newNum = +num
  if (isNaN(newNum)) {
    return num
  }

  const parts = newNum.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}

export function currencySymbol(value, currency) {
  if (!value) return ''
  return (store.getters.currencySymbolMapping[currency] || '') + toThousands(value)
}
