<template>
  <a-select
    allowClear
    :mode="mode"
    show-search
    :maxTagCount="4"
    style="width: 100%"
    :placeholder="_selfPlaceholder"
    :disabled="_selfDisabled"
    :default-active-first-option="false"
    v-model="selectedValue"
    :filter-option="filterOption"
    :not-found-content="loading ? undefined : null"
    @focus="getList"
    @change="onChange"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-for="(item, index) in option" :value="item[valueName]" :key="index">
      {{ item[labelName] }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
// 通过接口调用获取list
export default {
  props: {
    placeholder: {},
    disabled: {},
    code: {},
    name: {},
    mode: {
      type: String,
      default: '',
    },
    api: {
      type: String,
    },
    params: {
      type: Object,
      default: () => {
        return {}
      },
    },
    init: {
      //是否在mounted调用getList
      type: Boolean,
      default: false,
    },
    valueName: {
      type: String,
      default: 'code',
    },
    labelName: {
      type: String,
      default: 'name',
    },
  },
  data() {
    return {
      selectedValue: undefined,
      option: [],
      loading: false,
    }
  },
  mounted() {
    if (this.init) {
      this.getList()
    }
  },
  computed: {
    _selfPlaceholder() {
      return this.placeholder
    },
    _selfDisabled() {
      return this.disabled || false
    },
  },
  watch: {
    code() {
      if (this.option.length > 0) {
        this.setDefault()
      } else if (!this.name) {
        this.getList()
      }
    },
    name: {
      handler(val) {
        this.selectedValue = val
      },
      immediate: true,
    },
  },
  methods: {
    async getList() {
      if (this.option.length > 0) return
      this.loading = true
      let res = await http({
        url: api[this.api],
        data: {
          ...this.params,
        },
      })
      this.loading = false
      let list = res.result.list || []
      this.option = list
      this.setDefault()
    },
    setDefault() {
      if (this.name) return
      if (this.code != undefined) {
        this.onChange(this.code)
      } else if (this.option.length === 1) {
        this.onChange(this.option[0][this.valueName])
      }
    },
    onChange(value) {
      this.selectedValue = value
      this.$emit('update:code', value)
      let item = this.option.find((elem) => elem[this.valueName] === value) || {}
      this.$emit('change', item)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>
