import i18n from '@/utils/lang'
import store from '@/store'
import { deleteCookie } from '@common/cookie'
import { singleSignOnURL, singleSignOutURL } from '@/config/config'

function isEmptyObject(obj) {
  // 检查参数是否为对象
  if (typeof obj !== 'object' || obj === null) {
    return true
  }

  // 检查对象是否有可枚举的属性
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }

  return true
}

function copy(data) {
  const url = data
  const oInput = document.createElement('input')
  oInput.value = url
  document.body.appendChild(oInput)
  oInput.select() // 选择对象;
  document.execCommand('Copy') // 执行浏览器复制命令
  layer.msg(i18n.t('已复制'), { timeout: 800 })
  oInput.remove()
}

function getPermission(keyList = []) {
  let userSession = localStorage.getItem('userSession')
  if (userSession) {
    userSession = JSON.parse(userSession)
  }
  let funcList = userSession.funcList
  keyList.forEach((item, index) => {
    funcList = funcList?.find((elem) => elem.code === item)
    if (index < keyList.length - 1) {
      funcList = funcList?.children || []
    }
  })
  return funcList !== undefined
}

function toFixedUp(value, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces)
  return (Math.ceil(value * factor) / factor).toFixed(decimalPlaces)
}

/**
 *
 * @param {Array} list 单位列表
 * @param {Object} targetUnit 指定单位
 * @param {Object} baseUnit 被转换单位
 */
const getUnitRate = ({ list, targetUnit, baseUnit }) => {
  if (list && !baseUnit) {
    baseUnit = list.find((item) => item.baseUnit) || {}
  }
  let rate = 1,
    unit = baseUnit.unitName,
    alias = baseUnit.unitNameFull
  if (targetUnit) {
    rate = targetUnit.conversionRate / baseUnit.conversionRate
    unit = targetUnit.unitName
    alias = targetUnit.unitNameFull
  }
  return {
    rate,
    unit,
    alias,
    base_unit: baseUnit.unit,
  }
}

function convertKeysToCamelCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item))
  }

  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  const result = {}
  Object.keys(obj).forEach((key) => {
    const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
    result[camelCaseKey] = convertKeysToCamelCase(obj[key])
  })

  return result
}

//将字符串空格分分隔成逗号分隔
const spaceToComma = (str = '') => {
  return str
    .trim()
    .split(' ')
    .filter((item) => item)
    .join(',')
}

function logout() {
  deleteCookie()
  localStorage.clear()
  window.location.href = singleSignOutURL + '?redirect_uri=' + encodeURIComponent(singleSignOnURL)
}

function buildTree(nodes) {
  const map = {}
  nodes.forEach((item) => {
    item.title = item.name
    item.key = item.code
    map[item.code] = item
  })
  const tree = []
  nodes.forEach((item) => {
    const parent = map[item.parent_code]
    if (parent) {
      if (!parent.children) {
        parent.children = []
        parent.childrenCodeList = []
      }
      item.key = item.parent_code + '-' + item.code
      parent.children.push(item)
      parent.childrenCodeList.push(item.code)
    } else {
      tree.push(item)
    }
  })
  return tree
}

const getTableHeight = (height) => {
  if (store.state.rate > 1) {
    height = height * store.state.rate
  }
  return height
}

export {
  isEmptyObject,
  copy,
  getPermission,
  toFixedUp,
  getUnitRate,
  convertKeysToCamelCase,
  spaceToComma,
  logout,
  buildTree,
  getTableHeight,
}
