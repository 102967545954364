export default [
  {
    path: '/',
    name: 'index',
    redirect: '/login',
    component: () => import('./view/index'),
    children: [
      {
        path: '/payableManage/payableList',
        name: 'payableList',
        component: () => import('./view/payableManage/payableList.vue'), //应付订单列表
      },
      {
        path: '/payableManage/payableDetail',
        name: 'payableDetail',
        component: () => import('./view/payableManage/payableDetail.vue'), //应付订单详情
      },
      {
        path: '/payableManage/paymentRequestList',
        name: 'paymentRequestList',
        component: () => import('./view/payableManage/paymentRequestList.vue'), //付款申请单列表
      },
      {
        path: '/payableManage/paymentRequestDetail',
        name: 'paymentRequestDetail',
        component: () => import('./view/payableManage/paymentRequestDetail.vue'), //付款申请单详情
      },
      {
        path: '/payableManage/paymentNoteList',
        name: 'paymentNoteList',
        component: () => import('./view/payableManage/paymentNoteList.vue'), //付款单列表
      },
      {
        path: '/payableManage/paymentNoteDetail',
        name: 'paymentNoteDetail',
        component: () => import('./view/payableManage/paymentNoteDetail.vue'), //付款单详情
      },
      {
        path: '/payableManage/depositPaymentList',
        name: 'depositPaymentList',
        component: () => import('./view/payableManage/depositPaymentList.vue'), //预付单列表
      },
      {
        path: '/payableManage/depositPaymentDetail',
        name: 'depositPaymentDetail',
        component: () => import('./view/payableManage/depositPaymentDetail.vue'), //预付单详情
      },
      {
        path: '/receivableManage/receivableList',
        name: 'receivableList',
        component: () => import('./view/receivable/receivableList.vue'), //应收订单列表
      },
      {
        path: '/receivableManage/receivableDetail',
        name: 'receivableDetail',
        component: () => import('./view/receivable/receivableDetail.vue'), //应收订单详情
      },
      {
        path: '/receivableManage/receiptNoteList',
        name: 'receiptNoteList',
        component: () => import('./view/receivable/receiptNoteList.vue'), //收款单列表
      },
      {
        path: '/receivableManage/receiptNoteDetail',
        name: 'receiptNoteDetail',
        component: () => import('./view/receivable/receiptNoteDetail.vue'), //收款单详情
      },
      {
        path: '/invoiceManage/invoiceList',
        name: 'invoiceList',
        component: () => import('./view/invoiceManage/invoiceList.vue'), //发票列表
      },
      {
        path: '/invoiceManage/invoiceDetail',
        name: 'invoiceDetail',
        component: () => import('./view/invoiceManage/invoiceDetail.vue'), //新建发票
      },
      {
        path: '/baseInfo/accountElement',
        name: 'accountElement',
        component: () => import('./view/baseInfo/accountElement.vue'), //会计要素
      },
      {
        path: '/baseInfo/accountList',
        name: 'accountList',
        component: () => import('./view/baseInfo/accountList.vue'), //会计科目
      },
      {
        path: '/baseInfo/accountDetail',
        name: 'accountDetail',
        component: () => import('./view/baseInfo/accountDetail.vue'), //会计科目
      },
      {
        path: '/baseInfo/accountTable',
        name: 'accountTable',
        component: () => import('./view/baseInfo/accountTable.vue'), //科目表
      },
      {
        path: '/baseInfo/supplierList',
        name: 'supplierList',
        component: () => import('./view/baseInfo/supplierList.vue'), //供应商
      },
      {
        path: '/baseInfo/settlementMethod',
        name: 'settlementMethod',
        component: () => import('./view/baseInfo/settlementMethod.vue'), //计算方式
      },
      {
        path: '/voucherManage/voucherList',
        name: 'voucherList',
        component: () => import('./view/voucherManage/voucherList.vue'), //凭证列表
      },
      {
        path: '/voucherManage/voucherDetail',
        name: 'voucherDetail',
        component: () => import('./view/voucherManage/voucherDetail.vue'), //凭证详情
      },
      {
        path: '/404',
        name: '404',
        component: () => import('./view/common/404'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./view/login'),
  },
  {
    path: '/403',
    name: '403',
    component: () => import('./view/common/403'),
  },
  // 此处需特别注意至于最底部
  {
    path: '*',
    redirect: '/404',
  },
]
