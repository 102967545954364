import Vue from 'vue'
import './public-path'
import App from './App.vue' // 根组件
import VueRouter from 'vue-router'
import routes from './router'
import store from './store'
import './utils/antd'
import './css/index.scss'
import breadcrumb from '@component/breadcrumb/index'
import PriceInput from '@component/priceInput'
import CommonQuerySelect from '@component/commonQuerySelect'
import CommonSelect from '@component/commonSelect'
import CommonTable from '@component/commonTable'
import OperateBtn from '@component/operateBtn'
import actions from './qiankun/actions'
import i18n from './utils/lang'
import { getCookie } from '@/common/cookie'
// 注册全局过滤器
import * as filters from './common/filters' // global filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
  Vue.prototype['$' + key] = filters[key]
})
Vue.component('breadcrumb', breadcrumb)
Vue.component('PriceInput', PriceInput)
Vue.component('CommonSelect', CommonSelect)
Vue.component('OperateBtn', OperateBtn)
Vue.component('CommonQuerySelect', CommonQuerySelect)
Vue.component('CommonTable', CommonTable)

Vue.prototype.$isEmpty = (val) => {
  return val === null || val === undefined || val === ''
}
const env = 'dev'

Vue.config.devtools = env === 'dev' ? true : false
Vue.config.silent = env === 'dev' ? false : true

let router = null
let instance = null
function render(props = {}) {
  const { container, erpLogout, erpJump, showAppDrawer } = props
  if (props) {
    // 注入 actions 实例
    actions.setActions(props)
    Vue.prototype.$erpLogout = erpLogout
    Vue.prototype.$erpJump = erpJump
    Vue.prototype.$showAppDrawer = showAppDrawer
  }
  router = new VueRouter({
    // 注意这里的name,最好不要写死，直接使用主应用传过来的name
    base: window.__POWERED_BY_QIANKUN__ ? `FMS` : '/',
    mode: 'history',
    routes,
  })

  router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
      if (!getCookie('d_session_id')) {
        next()
      } else {
        next({ name: 'payableList' })
      }
    } else if (!getCookie('d_session_id')) {
      localStorage.setItem('pageCacheFullPath', to.fullPath)
      next({ name: 'login' })
      return
    }
    next()
  })

  Vue.use(VueRouter)
  instance = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export function getRouter() {
  return router
}

export async function bootstrap() {
  console.log('[vue2] fms app bootstraped')
}

export async function mount(props) {
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  router = null
}
