<template>
  <a-button :type="type" :disabled="disabled" :loading="checkLoading" @click="checkHandle">
    {{ text }}
  </a-button>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
export default {
  props: {
    api: {
      type: String,
    },
    type: {
      type: String,
      default: 'primary',
    },
    text: { type: String },
    params: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    checkHandle() {
      this.checkLoading = true
      let url = this.api ? api[this.api] : api.payPaymentNote
      http({
        url,
        data: {
          ...this.params,
        },
        success: (res) => {
          this.checkLoading = false
          if (res.success) {
            this.$success({
              title: this.$t(`layer.Success`),
              content: this.text + this.$t(`成功`),
            })
            this.$emit('success')
          }
        },
        fail: (err) => {
          console.log('err: ', err)
          this.$message.error(err.msg || err.message || this.$t('操作失败'))
          this.checkLoading = false
        },
      })
    },
  },
  data() {
    return {
      checkLoading: false,
    }
  },
}
</script>
