<template>
  <div class="table-content">
    <div class="table-header">
      <div class="row status-list-wrap" v-if="statusList.length > 0">
        <a-tabs default-active-key="" @change="setSearchStatus">
          <a-tab-pane key="">
            <template slot="tab">
              <span>{{ $t('form.All') }}</span>
            </template>
          </a-tab-pane>
          <a-tab-pane v-for="item in statusList" :key="item.code">
            <template slot="tab">
              <span>{{ $t(item.name) }}</span>
            </template>
          </a-tab-pane>
        </a-tabs>
      </div>
      <ul class="action-list flex" v-show="dataSource.length">
        <li style="margin-right: 15px">
          <slot name="header"></slot>
        </li>
      </ul>
    </div>
    <div class="table-body">
      <a-table
        :row-selection="
          showSelect
            ? {
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }
            : null
        "
        :size="size"
        :loading="loading"
        :scroll="scroll"
        :columns="commonColumns"
        :data-source="dataSource"
        :rowKey="rowKey ? rowKey : (record, index) => index"
        :pagination="false"
        v-bind="tableProps"
      >
        <template slot="no" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
        <template
          v-for="column in columns"
          :slot="column.scopedSlots?.customRender"
          slot-scope="text, record, index"
        >
          <slot
            :name="column.scopedSlots?.customRender"
            :text="text"
            :record="record"
            :index="index"
          />
        </template>
      </a-table>
    </div>
    <div class="table-footer" v-if="showPagination">
      <pagination
        :pageNo="pageNo"
        :length="pageSize"
        :total-count="total"
        @paginate="paginate"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from '@/component/pagination.vue'
export default {
  components: {
    pagination,
  },
  props: {
    statusList: { type: Array, default: () => [] },
    dataSource: {
      type: Array,
      default: () => [],
    },
    showSelect: {
      //是否可以选择
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
    },
    tableProps: {
      type: Object,
      default: () => ({}),
    },
    scroll: {
      type: Object,
      default: () => ({}),
    },
    total: {
      type: Number,
    },
    selectedRowKeys: {
      type: Array,
      default: () => [],
    },
    flexed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 20,
      selecteds: [],
      status: undefined,
    }
  },
  computed: {
    commonColumns() {
      return [
        {
          title: '#',
          align: 'center',
          key: 'NO',
          width: 60,
          fixed: this.flexed ? 'left' : '',
          scopedSlots: {
            customRender: 'no',
          },
        },
        ...this.columns,
      ]
    },
  },
  watch: {
    selecteds(newValue) {
      this.$emit('update:selectedRowKeys', newValue)
    },
  },
  methods: {
    queryList(page_no) {
      if (page_no) {
        this.pageNo = page_no
      }
      this.$emit('search', { page_no: this.pageNo, page_size: this.pageSize, status: this.status })
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.queryList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selecteds = selectedRowKeys
      this.$emit('select', selectedRowKeys, selectedRows)
    },
    setSearchStatus(status) {
      this.status = status
      this.pageNo = 1
      this.selecteds = []
      this.$nextTick(() => {
        this.queryList()
      })
    },
  },
}
</script>
