import HostUrl from '@/utils/HostUrl.js'

let hostUrl = new HostUrl()

let host = hostUrl.getUrl(''),
  fms_host = hostUrl.getUrl('FMS'),
  goods_host = hostUrl.getUrl('GOODS'),
  scm_host = hostUrl.getUrl('SCM'),
  shop_host = hostUrl.getUrl('SHOP'),
  idaas_host = hostUrl.getUrl('IDAAS')

export default {
  host: host,
  authorizeOauth: host + '/oauth/authorize', //单点登录解析
  getSession: host + '/user/session', //user/session

  switchParty: idaas_host + '/open/user/switchBu', //切换业务组
  merchantList: idaas_host + '/open/merchant/queryPage', //获取商户
  switchMerchant: idaas_host + '/open/switchMerchant', //切换商户
  switchApp: idaas_host + '/open/user/switchApp', //切换APP
  querySystemMessage: idaas_host + '/open/systemMessage/receive', //发布消息
  queryIdaasAppList: idaas_host + '/open/merchantApp/list', //idaas应用列表
  getDepartmentList: idaas_host + '/open/department/listDepartment', //结算组织列表

  //goodsjieku
  getSkuList: goods_host + '/sku/query', //查询sku列表
  batchImportPurchaseOrderItems: scm_host + '/purchaseOrder/batchImportPurchaseOrderItems', //导入采购单商品信息
  //shop接口
  getWarehouseListNew: shop_host + '/warehouse/queryWarehousePage', //仓库列表
  getWarehouseShipperList: shop_host + '/shipper/page', //货主列表
  getNewShopInfoList: shop_host + '/shop/simplePage', //新店铺选择

  getInvoiceList: fms_host + '/invoice/query/list',
  getInvoiceDetail: fms_host + '/invoice/detail',
  getMappingList: fms_host + '/invoice/get/map',
  createOrUpdateInvoice: fms_host + '/invoice/createOrUpdate',
  filePreUpload: fms_host + '/file/preUpload',
  fileUpload: fms_host + '/file/upload',
  //应付单
  getPayableList: fms_host + '/payable/order/list',
  getPayableDetail: fms_host + '/payable/order/detail',
  createPayableOrder: fms_host + '/payable/order/create', //创建应付单
  cancelPayableOrder: fms_host + '/payable/order/cancel', //作废应付单
  //预付单
  getDepositPaymentList: fms_host + '/deposit/payment/list',
  getDepositPaymentDetail: fms_host + '/deposit/payment/detail',
  createDepositPayment: fms_host + '/deposit/payment/createOrUpdate',
  cancelDepositPayment: fms_host + '/deposit/payment/cancel',
  //付款申请单
  getPaymentRequestList: fms_host + '/payment/request/list',
  getPaymentRequestDetail: fms_host + '/payment/request/detail',
  createPaymentRequest: fms_host + '/payment/request/createOrUpdate',
  cancelPaymentRequest: fms_host + '/payment/request/cancel',
  //付款单
  getPaymentNoteList: fms_host + '/payment/note/list',
  getPaymentNoteDetail: fms_host + '/payment/note/detail',
  createPaymentNote: fms_host + '/payment/note/createOrUpdate',
  cancelPaymentNote: fms_host + '/payment/note/cancel', //作废
  payPaymentNote: fms_host + '/payment/note/confirm/pay', //确认付款
  //发票
  bindInvoicePayable: fms_host + '/invoice/bind',
  payInvoice: fms_host + '/invoice/pay',
  rejectPayInvoice: fms_host + '/invoice/reject/paid',
  modifyInvoiceAmount: fms_host + '/invoice/modify/amount',
  checkInvoice: fms_host + '/invoice/check',
  //凭证
  getVoucherTemplateList: fms_host + '/voucher/template/list', //凭证模板列表
  generateVoucher: fms_host + '/voucher/generate', //生成凭证
  getVoucherList: fms_host + '/voucher/list', //凭证列表
  getVoucherDetail: fms_host + '/voucher/detail', //凭证详情
  crateOrUpdateVoucher: fms_host + '/voucher/crateOrUpdate', //手动创建凭证
  checkVoucher: fms_host + '/voucher/check', //凭证审核
  //基础信息
  getAccountElementist: fms_host + '/account/element/list', //会计要素列表
  getAccountList: fms_host + '/account/list', //会计科目列表
  getAccountDetail: fms_host + '/account/detail', //会计科目详情
  getAccountTableList: fms_host + '/account/table/list', //科目表列表
  createOrUpdateAccountTable: fms_host + '/account/table/createOrUpdate', //创建科目表
  getAccountSetList: fms_host + '/account/set/list', //账簿列表

  queryPurchaseSupplierList: fms_host + '/supplier/list', //供应商名称列表
  getCurrencyList: fms_host + '/baseInfo/currencyPage', //货币列表
  getcurrencyRateList: fms_host + '/baseInfo/currencyRatePage', //货币汇率列表
  getSettlementMethodList: fms_host + '/baseInfo/settlementMethod', //结算方式列表
  getBusinessUnitList: fms_host + '/business/unit/list', //结算组织列表
  queryAccountingCalendarDetailPage: fms_host + '/baseInfo/queryAccountingCalendarDetailPage', //会计期间列表

  //应收单
  getReceivableList: fms_host + '/receivable/order/list',
  getReceivableDetail: fms_host + '/receivable/order/detail',
  createReceivableOrder: fms_host + '/receivable/order/save', //创建应收单
  operateReceivableOrder: fms_host + '/receivable/order/operate',
  //收款单
  getReceiptNoteList: fms_host + '/receipt/note/list',
  getReceiptNoteDetail: fms_host + '/receipt/note/detail',
  createReceiptNote: fms_host + '/receipt/note/save',
  operateReceiptNote: fms_host + '/receipt/note/operate',
  //审核接口
  checkOrder: fms_host + '/doc/check',
  getCommonList: fms_host + '/common/list',
}
