export default {
  partyId: (state) => state.userSession?.currentBu?.id,
  currencyListMapping: (state) => listToMap(state.currencyList),
  currencySymbolMapping: (state) => listToMap(state.currencyList, 'currency_symbol'),
  documentTypeMapping: (state) => listToMap(state.document_type),
  payableOrderStatusMapping: (state) => listToMap(state.payable_order_status),
  payableOrderTypeMapping: (state) => listToMap(state.payable_order_type),
  invoiceTypeMapping: (state) => listToMap(state.invoice_type),
  invoiceStatusMapping: (state) => listToMap(state.invoice_status),
  payableOrderInvoiceStatusMapping: (state) => listToMap(state.payable_order_invoice_status),
  paymentRequestStatusMapping: (state) => listToMap(state.payment_request_status),
  paymentRequestTypeMapping: (state) => listToMap(state.payment_request_type),
  paymentNoteStatusMapping: (state) => listToMap(state.payment_note_status),
  paymentNoteTypeMapping: (state) => listToMap(state.payment_note_type),
  depositPaymentTypeMapping: (state) => listToMap(state.deposit_payment_type),
  depositPaymentStatusMapping: (state) => listToMap(state.deposit_payment_status),
  sourceOrderTypeMapping: (state) => listToMap(state.source_order_type),
  bizOrderStatusMapping: (state) => listToMap(state.payable_order_biz_status),
  journalVoucherTypeMapping: (state) => listToMap(state.journal_voucher_type),
  journalVoucherStatusMapping: (state) => listToMap(state.journal_voucher_status),
  receivableOrderStatusMapping: (state) => listToMap(state.receivable_order_status),
  receivableOrderTypeMapping: (state) => listToMap(state.receivable_order_type),
  receiptNoteStatusMapping: (state) => listToMap(state.receipt_note_status),
  receiptNoteTypeMapping: (state) => listToMap(state.receipt_note_type),
}

function listToMap(list = [], label = 'name') {
  let obj = {}
  list.forEach((item) => {
    obj[item.code] = item[label]
  })
  return obj
}
